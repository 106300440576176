import React from 'react'
import Layout from '../../components/layout/layout'
import PageHeader from '../../components/pageheader/pageheader'
import Section from '../../components/section/section'

const MonthlyPage = () => {
  return (
    <Layout>

      <PageHeader title="Monthly Promotions" />

      <Section color="white">
        <div className="container">
          <div className="text-center">
            <h3 className="section-subheading text-muted mb-5">Some kind of intro about the monthly promotions</h3>
          </div>
          <div className="row align-items-end justify-content-center text-center">
            
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default MonthlyPage
